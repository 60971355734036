var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "flex col a-start" }, [
    _c("span", {
      domProps: { textContent: _vm._s(_vm.filters.clockTime(_vm.data)) },
    }),
    _c("span", {
      domProps: {
        textContent: _vm._s(_vm.filters.date(_vm.data, "DD/MM/YYYY")),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }